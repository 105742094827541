.YearInReview-navigator {
  display: flex;
  gap: 12px;
  align-items: center;

  .prev, .next {
    margin-top: 8px;
  }
}

@media screen and (min-width: 1200px) {
  .YearInReview-navigator {
    position: relative;
    gap: 0;

    .prev {
      position: absolute;
      right: -44px;
      top: 0;
    }

    .next {
      position: relative;
      right: 12px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .YearInReviewScreen-columns {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}